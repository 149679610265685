@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.45;
    font-family: "Open Sans", sans-serif;
    color: #171e26;
    padding-top: 7.8rem;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

img {
    max-width: 100%;
}

.ZataHub-header {
    .btn {
        padding: 0.9rem 3.75rem;
    }
}

.btn {
    font-family: "Montserrat", sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 0.9rem 2.8rem;
    background: linear-gradient(180deg, #FF9749 2.6%, #FF6D00 78.97%);
    border: 1px solid #ff6d00;
    color: #fff;
    border-radius: 10rem;
    min-width: 13.4rem;
    span {
        margin-right: 1rem;
    }
    &:hover {
        background: #fff0e5;
        color: #ff6d00;
        border: 1px solid #ff6d00;
    }
    &:active {
        background: transparent;
        color: #ff6d00;
        border: 1px solid #ff6d00;
    }
    &.outline-btn {
        background: transparent;
        color: #ff6d00;
        &:hover {
            background: #fff0e5;
            color: #ff6d00;
            border: 1px solid #ff6d00;
        }
        &:active {
            background: linear-gradient(180deg, #FF9749 2.6%, #FF6D00 78.97%);
            color: #ffffff;
            border: 1px solid #ff6d00;
        }
    }
    &+.btn {
        margin-left: 1.8rem;
    }
    &.gray-btn {
        background: #736D83;
        border-color: #736D83;
        &:hover,
        &:active {
            background: transparent;
            color: #736D83;
        }
    }
}

.text-link {
    color: #ff6d00;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
}

.small-title {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 26px;
    color: #ff6d00;
    margin-bottom: 1rem;
}

h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 7.2rem;
    margin: 0;
    .bg-text {
        font-size: 58px;
        line-height: 8.7rem;
        background-color: #ff6d00;
        color: #fff;
        padding: 0 5px;
        border-radius: 4px;
    }
}

.repeat-section {
    padding: 10rem 0;
}

.bg-fff6f0 {
    background-color: #FFF6F0;
}

.bg-573feb {
    background-color: #573FEB;
}

.bg-fecf25 {
    background-color: #FECF25;
}

.bg-f84d00 {
    background-color: #F84D00;
}

.bg-b4a6ff {
    background-color: #B4A6FF;
}

.bg-f3f4f6 {
    background: #F3F4F6;
}

.bg-b4a6ff-light {
    background-color: rgb(180 166 255 / 10%);
}

.section-title {
    max-width: 500px;
    h2 {
        font-size: 3.6rem;
        color: #171E26;
        font-weight: 600;
        margin-bottom: 1.2rem;
        line-height: 1.5;
        .bg-text {
            background-color: #ff6d00;
            color: #fff;
            padding: 0px 9px 3px;
            border-radius: 4px;
            margin-right: 7px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
    p {
        font-size: 1.8rem;
        margin: 0;
        line-height: 1.75;
        &+p {
            margin-top: 3rem;
        }
    }
    .btn {
        margin-top: 1.8rem;
    }
    &.text-center {
        max-width: 795px;
        margin: 0 auto 3rem;
    }
}

.mt-6 {
    margin-top: 6rem;
}

.swiper-container {
    padding-bottom: 2rem !important;
    .swiper-button-next,
    .swiper-button-prev {
        width: 4rem;
        height: 4rem;
        border-radius: 100%;
        background-color: #FF6D00;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: #FF6D00;
            color: #FFF;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &.swiper-button-disabled {
            opacity: 1;
            background-color: #FFF;
            color: #000000;
        }
    }
    .swiper-button-next {
        position: absolute;
        left: 5rem;
        bottom: 0;
        top: unset;
        &:after {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"><path d="M10.0254 0.94165L15.0837 5.99998L10.0254 11.0583" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/><path d="M0.916016 6H14.941" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>') no-repeat center;
        }
        &.swiper-button-disabled:after {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"><path d="M10.0254 0.94165L15.0837 5.99998L10.0254 11.0583" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/><path d="M0.916016 6H14.941" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>') no-repeat center;
        }
    }
    .swiper-button-prev {
        position: absolute;
        left: 0;
        bottom: 0;
        top: unset;
        &:after {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"><path d="M5.97435 0.94165L0.916016 5.99998L5.97435 11.0583" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/><path d="M15.0836 6H1.05859" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>') no-repeat center;
        }
        &.swiper-button-disabled:after {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"><path d="M5.97435 0.94165L0.916016 5.99998L5.97435 11.0583" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/><path d="M15.0836 6H1.05859" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>') no-repeat center;
        }
    }
    .swiper-pagination {
        display: none;
    }
}

.inner-banner-section {
    background-color: #FFF6F0;
    padding: 10rem 0;
    min-height: 370px;
    position: relative;
    .left-curve-line {
        position: absolute;
        left: 0;
        top: 5.7rem;
    }
    .circle-icon {
        position: absolute;
        right: 6px;
        bottom: 18px;
    }
    .inner-banner-title {
        text-align: center;
        .small-title {
            margin-bottom: 2rem;
        }
    }
    &.vertical-center {
        display: flex;
        align-items: center;
    }
}

.fadeAnimationRightCustom {
    animation: fadeAnimationRightCustom 1s ease both;
}

@keyframes fadeAnimationRightCustom {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.form-control {
    height: 5.6rem;
    border: 1px solid #717B8C;
    border-radius: 8px;
    padding: 2rem 2rem 2rem 7.2rem;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 400;
    &:placeholder {
        color: #736D83;
    }
    &:focus {
        border: 1px solid #FF6D00;
        outline: none;
        box-shadow: none;
    }
}

.email-input {
    position: relative;
    .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 7.2rem;
        height: 9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF6D00;
    }
    .form-control {
        height: 9rem;
    }
}

.form-group {
    &:not(:last-child) {
        margin-bottom: 2.5rem;
    }
}

.error {
    display: block;
    font-size: 1.8rem;
    margin: 1rem 0 0;
    font-family: "Montserrat", sans-serif;
    color: red;
}

form {
    .btn {
        height: 6.6rem;
        font-family: "Montserrat", sans-serif;
        font-size: 30px;
        font-weight: 600;
    }
}

.pt-100 {
    padding-top: 10rem;
}


/* Responsive css */

@media (max-width: 1199px) {
    body {
        padding-top: 6.1rem;
    }
    h1 {
        font-size: 3.2rem;
        line-height: 4.16rem;
        .bg-text {
            font-size: 3.6rem;
            line-height: 4.68px;
        }
    }
    .small-title {
        font-size: 1.4rem;
        line-height: 20px;
    }
    .btn {
        font-size: 1.4rem;
        min-width: 7rem;
    }
    .section-title {
        max-width: 560px;
        margin: 0 auto 4rem;
        text-align: center;
        h2 {
            font-size: 2.6rem;
            br {
                display: none;
            }
        }
        p {
            font-size: 1.6rem;
        }
    }
    .repeat-section {
        padding: 5rem 0;
    }
    .container {
        padding: 0 3rem;
    }
    .swiper-container {
        .swiper-button-next,
        .swiper-button-prev {
            bottom: 5rem;
        }
    }
    .inner-banner-section {
        padding: 5rem 0;
        min-height: 270px;
        .left-curve-line {
            max-height: 3.45rem;
            top: 3rem;
        }
        .circle-icon {
            max-height: 8.5rem;
        }
        .inner-banner-title {
            .small-title {
                margin-bottom: 1rem;
            }
        }
    }
    .email-input {
        .form-control {
            height: 7rem;
            font-size: 1.6rem;
        }
        .icon {
            height: 7rem;
        }
    }
    form {
        .btn {
            height: 6.2rem;
            font-size: 2rem;
        }
    }
    .pt-100 {
        padding-top: 5rem;
    }
}

@media (max-width:767px) {
    .mt-6 {
        margin-top: 0;
    }
    .swiper-container {
        .swiper-button-next {
            left: 4rem;
        }
        .swiper-button-next,
        .swiper-button-prev {
            bottom: 0;
            width: 3rem;
            height: 3rem;
        }
    }
    .inner-banner-section {
        min-height: 265px;
        .left-curve-line {
            max-height: 19px;
        }
        .circle-icon {
            max-height: 5rem;
            bottom: 3rem;
        }
    }
}