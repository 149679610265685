.container {
    max-width: 1240px;
}

.ZataHub-header {
    height: 7.8rem;
    padding: 1.6rem 0;
    background-color: #fff;
    box-shadow: 0 4px 13px rgb(0 0 0 / 14%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    nav {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .logo {
            display: block;
        }
        .menu-list {
            padding-left: 8.4rem;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 3.2rem;
                .sub-menu {
                    a {
                        font-family: Montserrat;
                        font-size: 1.6rem;
                        text-decoration: none;
                        color: #171e26;
                        &.active,
                        &:hover {
                            color: #ff6d00;
                        }
                    }
                }
            }
        }
        .right-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 30%;
            margin-left: auto;
        }
    }
    &.is-sticky {
        animation: fadeInDown .5s ease-in-out 0s 1 normal none running;
        background-color: #fff!important;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, .09);
        left: 0;
        padding-bottom: 0;
        padding-top: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }
}

.toggle-btn,
.close-menu {
    display: none;
}

.after-login-menu {
    display: flex;
    align-items: center;
    gap: 3rem;
    .notifications {
        a {
            color: #171E26;
        }
    }
    .profile-detail {
        display: flex;
        align-items: center;
        gap: 2rem;
        .profile-image {
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 100%;
        }
    }
    .dropdown-toggle {
        cursor: pointer;
        &::after {
            content: "";
            background-image: url('../images/icons/arrow.png');
            border: none;
            width: 18px;
            height: 9px;
            background-repeat: no-repeat;
        }
        label {
            cursor: pointer;
        }
    }
    .dropdown-menu {
        width: 100%;
        font-size: 1.6rem;
        font-family: "Montserrat", sans-serif;
        .dropdown-item {
            padding: 1rem;
            &:not(:last-child) {
                border-bottom: 1px solid #f1f1f1;
            }
            &:hover {
                background-color: transparent;
            }
        }
    }
    .btn {
        background: transparent;
        color: #171E26;
        display: flex;
        align-items: center;
        border: 0;
        gap: 2rem;
        border-radius: 0;
        padding: 0;
        &.active,
        &:active {
            background: transparent;
            border-color: transparent;
            color: #171E26;
        }
    }
}


/* Responsive css*/

@media (max-width:1199px) {
    .ZataHub-header {
        padding: 1.6rem 0;
        height: 6.1rem;
        nav {
            .logo {
                img {
                    max-height: 3rem;
                }
            }
            .menu-list {
                padding-left: 5rem;
                ul {
                    .sub-menu {
                        a {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
            .right-btn {
                .btn {
                    min-width: 8rem;
                    padding: .6rem 1.8rem;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .ZataHub-header {
        .close-menu {
            z-index: 999;
            position: fixed;
            top: 0;
            width: 5rem;
            height: 5rem;
            border: none;
            right: 60%;
            transform: translateX(0%);
            transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
            display: block;
            background: #ff6d00;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
        }
        nav {
            .logo {
                order: 2;
            }
            .right-btn {
                order: 3;
                width: auto;
            }
            .menu-list {
                ul {
                    left: 0;
                    top: 0;
                    margin-top: 0 !important;
                    width: 95% !important;
                    // height: 100vh;
                    overflow: auto;
                    // box-shadow: 0 0.3rem 0.6rem rgb(0 0 0 / 10%);
                    z-index: 1;
                    padding: 3rem;
                    display: block;
                    position: absolute;
                    background: #fff;
                    flex-direction: column;
                    width: 100%;
                    align-items: flex-start;
                    justify-content: flex-start;
                    box-shadow: 0px 4px 88px 0px #0000000F;
                    li {
                        &:not(:last-child) {
                            margin-bottom: 3rem;
                        }
                        a {
                            font-size: 1.8rem;
                        }
                    }
                    .sub-menu {
                        a {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
        .toggle-btn {
            width: 3rem;
            height: 3rem;
            background-color: transparent;
            border: 0;
            position: relative;
            display: block;
            order: 1;
            margin-right: 2rem;
            background-color: #171E26;
            padding: 6px;
            border-radius: 100%;
            transition: .3s;
            span {
                display: block;
                height: 1.5px;
                width: 100%;
                opacity: 1;
                right: 0;
                border-radius: 50px;
                margin: 3px 0;
                border: 1.5px solid #FFFFFF;
            }
            &.active {
                background-color: #B7C0CC;
                span {
                    &.cross-bar {
                        transform: rotate(45deg);
                        position: absolute;
                        width: 12px;
                        top: 11px;
                        left: 9px;
                        &:nth-child(2) {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
        .menu-list {
            width: 100%;
            top: 6.1rem;
            right: 0px;
            position: fixed;
            height: 100vh;
            // background: rgba(0, 0, 0, 0.76);
            z-index: 99999;
            transform: translateX(-200%);
            transition: all 0.3s ease-in-out 0s;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
    body {
        &.nav-open {
            overflow: hidden;
            .menu-list {
                transform: translateX(0%);
            }
        }
    }
    .after-login-menu {
        order: 3;
        gap: 1rem;
        margin-left: auto;
        .profile-detail {
            .profile-image {
                width: 30px;
                height: 30px;
                img {
                    max-width: 30px;
                    max-height: 30px;
                    object-fit: cover;
                }
            }
        }
        .dropdown-menu {
            .dropdown-item {
                font-size: 14px;
            }
        }
        .btn {
            gap: 1rem;
        }
    }
}